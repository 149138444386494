/* eslint-disable no-undef */
// eslint-disable-next-line no-shadow-restricted-names
(function ($, undefined) {

    if (typeof IB === 'undefined') {
      window.IB = {};
    } else if (IB.utag_events !== undefined) {
      return;
    } else if (IB.events_store !== undefined) {
      return;
    } else if (IB.VisitorSessionId !== undefined) {
      return;
    }

    /**
     * Utag Stack sender variables
     */
    let utagStack = []
    let isUtagBeingObserved = false
    let MAX_ATTEMPTS = 20

    /**
     * Try to send a utag event. If utag not defined yet, store the event in a stack
     * and try to send it 20 times before cancelling.
     * @param {Object} { type, data }
     */
    function queueUtagEvent(options) {
      let type = options.type || 'link';
      let data = options.data;

      if (typeof utag !== 'undefined') {
        utag[type](data)
      } else {
        utagStack.push({ type, data })

        if (isUtagBeingObserved) return

        isUtagBeingObserved = true
        let attempts = 0

        // eslint-disable-next-line no-inner-declarations
        function checkUtagDefined() {
          if ((typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined') || attempts === MAX_ATTEMPTS) {
            clearInterval(check_utag)

            utagStack.forEach(({ type, data }) => {
              utag[type](data)
            })

            utagStack = []
          }
          attempts += 1
        }

        let check_utag = setInterval(checkUtagDefined, 500)
      }
    }

    /**
     * The function sendUtagEvent is used to send events to utag/GA4
     * @param {{type : 'link', data : { event_name: 'searcher_hotel', event_cat: 'searcher', event_act: 'discount', event_lbl: 'discount for residents', event_purpose: 'booking', event_structure: 'body'}}} dataObjectExample Example of data object
     */
    function sendUtagEvent(options) {
      let type = options.type || 'link';
      let data = options.data;
      IB.VisitorSessionId.initNewVisitorSessionId()
      queueUtagEvent({ type, data })
    }

    let $document = $(document);

    /* utils */

    function getCleanedString(string){
      // Lo queremos devolver limpio en minusculas
      string = string.toLowerCase();

      // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
      string = string.replace(/á/gi,"a");
      string = string.replace(/é/gi,"e");
      string = string.replace(/í/gi,"i");
      string = string.replace(/ó/gi,"o");
      string = string.replace(/ú/gi,"u");
      // string = string.replace(/ñ/gi,"n");
      return string;
    }

    function search_action_type(place_value) {
      let action = "destination"
      switch (place_value.charAt(0)) {
        case 's':
          action = "specialty";
          break;
        case 'h':
          action = "hotel";
          break;
        case 'd':
          action = "destination";
          break;
      }
      return action
    }

    function normalizeFieldName(name) {
      return name.replace("[", "").replace("]", "").replace("iberostar_api_userfirst_name", "first_name").replace("_", " ")
    }

    function getFieldName(custom_field_name, name) {
      let field_name = '';
      if (custom_field_name != undefined && custom_field_name != "") {
        field_name = custom_field_name
      } else {
        field_name = name
      }
      return field_name;
    }


    function readEventsStore() {
      return JSON.parse(sessionStorage.getItem('events_store'))
    }

    function setEventsStore(events) {
      sessionStorage.setItem('events_store', JSON.stringify(events))
    }

    function removeEventsStore() {
      sessionStorage.removeItem('events_store');
    }

    function storeEvent(event) {
      let events = readEventsStore();
      if (events) {
        if (existHashOnArray(events, event) == -1)
          events.push(event);
      } else {
        events = new Array
        events.push(event)
      }

      setEventsStore(events);
    };

    function removeEvent(event) {
      let events = readEventsStore();
      let index  = existHashOnArray(events, event)
      if (events) {
        if (index != -1) {
          events.splice(index, 1);
        }
      }

      if (noEmpty(events))
        setEventsStore(events);
      else
        removeEventsStore()
    }

    function sendStoredEvents() {
      let events = readEventsStore();
      if (events) {
        // iterate over each element in the array
        $.each(events, function(i, event) {
          if (event.non_interaction === 1)
            utag_link_non_interaction(event.event_cat, event.event_act, event.event_lbl);
          else
            sendUtagEvent({
              data: {
                event_name: '',
                event_cat: event.event_cat,
                event_act: event.event_act,
                event_lbl: event.event_lbl,
                event_purpose: '',
                event_structure: '',
              },
            })

          removeEvent(event)
        });
      }
    }

    function get_utag_search_info(){
      $.ajax({
          dataType: 'JSON',
          type:     'GET',
          url:      '/ajax_contents/search_utag_info/',
          success:  function (data) {
            // Not working in Safari, in Private Browsing Mode
            try {
              $.each(data, function(key, value){
                if (value === "" || value === null || value === "not set" || value === "uninformed"){
                    delete data[key];
                }
              });
              utag_data = $.extend({}, utag_data, data);
              sessionStorage.setItem("utag_search_info", JSON.stringify(data));
            } catch (e) {
              console.log("Utag utag_search_info: "+e);
              console.log('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.');
            }
          }
        });
    }

    function sendSearchEvent(){
        // Not working in Safari, in Private Browsing Mode
        try {
            let utag_search_info = JSON.parse(sessionStorage.getItem("utag_search_info"));
            if(utag_search_info.search_term == "not set")
            utag_search_info.search_term = "destination";
            if(typeof utag_data !== "undefined" &&
              ((["funnel", "experiences", "search", "offers", "my iberostar", "destination"].indexOf(utag_data.page_section) != -1) ||
              (utag_data.page_section == "hotel" && utag_search_info["event_lbl"] == "without dates")) ){
                if(typeof utag !== "undefined"){
                  utag.link(utag_search_info);
                };
            }
            $.removeCookie("fastbooking_data_utag");
            sessionStorage.removeItem("utag_search_info");
        } catch (e) {
            console.log("Utag Send search event: "+e);
            console.log('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.');
        }
    }

    function existHashOnArray(array, hash) {
      found      = -1;
      hashString = JSON.stringify(hash);

      $.each(array, function(i, element) {
        if (JSON.stringify(element) === hashString) {
          found = i;
          return false;
        }
      });

      return found;
    }

    function cloneJson(data) {
      return JSON.parse(JSON.stringify(data));
    }
    /* END utils */

    /*Visitot ID*/
      // time expiration visitor_session_id
  let minutes = 30;
  let base_domain = document.domain.split('.').slice(-2)
    .join('.');
      // Return if exists a visitor_session_id cookie not expired.
  function hasCurrentVisitorSessionId() {
    return $.cookie('visitor_session_id') !== null && $.cookie('visitor_session_id') !== undefined;
  }

  function needsUpdateSessionExpire(date) {
    return $.cookie('visitor_session_id_expire') !== null && $.cookie('visitor_session_id_expire') !== undefined &&
            (date - Date.parse($.cookie('visitor_session_id_expire'))) > 90000;
  }
    function initNewVisitorSessionId(force) {
      let visitor_session_id;
      let hasCurrentSession = hasCurrentVisitorSessionId();
      let date = new Date();
      date.setTime(date.getTime() + (minutes * 60 * 1000));

      if (hasCurrentSession && force !== true) {
        visitor_session_id = $.cookie('visitor_session_id');
      } else {
        visitor_session_id =
          Math.random().toString(36).substring(2, 10) +
          Math.random().toString(36).substring(2, 10);
      }
      utag_data['visitor_session_id'] = visitor_session_id;
      if (!hasCurrentSession || force == true || needsUpdateSessionExpire(date)) {
        setCookies([
          {'name': 'visitor_session_id', 'value': visitor_session_id, 'expires': date, 'domain': '.' + base_domain},
          {'name': 'visitor_session_id_expire', 'value': date, 'expires': date, path: "/"}
        ]);
      }
    }

    /* function utag */

    function utag_link_non_interaction(cat, act, lbl) {
      try {
        utag.link({
          event_cat: cat,
          event_act: act,
          event_lbl: lbl,
          non_interaction: 1
        });
      } catch (err) {
        console.log(err);
      }
    }

    function utag_view(utag_info){
      if(typeof utag !== "undefined")
        utag.view(utag_info);
    }

    /* end function utag */

    /* function forms */

    function field_form_event(form_type, form_name, form_action, field_name, event_name = '', event_purpose = '', event_structure = '') {
      sendUtagEvent({
        data: {
          event_name: event_name,
          event_cat: form_type,
          event_act: form_action + '-' + form_name,
          event_lbl: field_name,
          event_purpose: event_purpose,
          event_structure: event_structure,
        },
      })
    }

    function error_form_event(form_name, form_action, field_name, form, event_name = '', event_purpose = '', event_structure = '') {
      let form_type = ($(form).data("form-type") == undefined || $(form).data("form-type") == "rfp") ? "form" : $(form).data("form-type");
      form_name = ($(form).data("form-type") == "rfp" ? "form-rfp" : form_name);

      sendUtagEvent({
        data: {
          event_name: event_name,
          event_cat: form_type,
          event_act: form_action + '-' + form_name,
          event_lbl: field_name,
          event_purpose: event_purpose,
          event_structure: event_structure,
        },
      })
    }

    function start_form_event(form_name, form_action, field_name, form, event_name = '', event_purpose = '', event_structure = '') {
      let form_is_sent = $(form).hasClass("form-sent"),
        form_type = ($(form).data("form-type") == undefined || $(form).data("form-type") == "rfp") ? "form" : $(form).data("form-type");
      form_name = ($(form).data("form-type") == "rfp" ? "rfp" : form_name);

      if (!form_is_sent){
        sendUtagEvent({
          data: {
            event_name: event_name,
            event_cat: form_type,
            event_act: form_action + '-' + form_name,
            event_lbl: field_name.replace("_id", "").replace("iberostar_api_user", "").replace("vo_not_logged_booking", ""),
            event_purpose: event_purpose,
            event_structure: event_structure,
          },
        })
      }
    }
    /* end function forms */

    function hotel_info_link(action, category, label, page_view, page_kind, $element) {
      let $utagDataElement = ($element.children("a").length ? $element.children("a") : $element),
        utag_page_url = $utagDataElement.data("utag-page-url"),
        utag_info = cloneJson(utag_data),
        event_utag_view = ($element.children("a").length && $element.children("a").data("event-utag-view") === false ? $element.children("a").data("event-utag-view") : true),
        utag_hotel_info = $utagDataElement.data("utag-hotel-info"),
        utag_page_category = $utagDataElement.data("utag-page-category"),
        utag_page_section = $utagDataElement.data("utag-page-section"),
        utag_page_name = $utagDataElement.data("utag-page-name"),
        utag_page_type = $utagDataElement.data("utag-page-type")

      utag_page_url = ((utag_page_url === undefined || utag_page_url === null) ? "" : utag_page_url.split("/").slice(0, -1).join("/") + "/" + page_kind);
      utag_info["page_url"] = utag_page_url;
      if (utag_page_name !== undefined){
        utag_info["page_name"] = window.location.href.replace("#","").split('?')[0]+utag_page_name;
        utag_info["page_url"] = window.location.href.replace("#","").split('?')[0]+utag_page_name;
        utag_info["page_pathname"] = window.location.pathname+utag_page_name;
      }
      else
        utag_info["page_name"] = utag_info["page_url"].replace((window.location.protocol + "//" + window.location.host), "");

      if (utag_page_category !== undefined)
        utag_info["page_category"] = utag_page_category;

      if (utag_page_section !== undefined)
        utag_info["page_section"] = utag_page_section;

      if (utag_page_type !== undefined)
        utag_info["page_type"] = utag_page_type;
      if (page_view === true && event_utag_view === true) {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId();
        utag_view(utag_info);
      }
      if (utag_hotel_info !== undefined) {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId();

        utag_info = $.extend({}, utag_info, utag_hotel_info);
        link_info = { event_cat: category, event_act: action, event_lbl: label, page_category: utag_info["page_category"],page_section: utag_info["page_section"], page_type: utag_info["page_type"]};
        link_info = $.extend({}, link_info, utag_hotel_info);
        utag.link(link_info);
      }
      else
        sendUtagEvent({
          data: {
            event_name: '',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: '',
            event_structure: '',
            force_cg: false,
          },
        })
    }
    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
    */
    function getMobileOperatingSystem(){
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "android";
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }
        return "desktop";
    }

    function init() {
      $("form.mice-hidden-form, form.ibepro-utag-register, #session-tab-registration form").find(':input').each(function () {
        let form = $(this).parents("form"),
            form_name = form.data("form-name"),
            custom_field_name = $(this).data("utag-normalize-field-name");

        switch (this.type) {
          case 'select-multiple':
          case 'select-one':
            $(this).one('click', function () {
              start_form_event(form_name, 'start form', getFieldName(custom_field_name, normalizeFieldName($(this).attr('name'))), $(this).parents("form"), 'start_form','information', 'body');
              form.addClass("form-sent");
            });
            break;
          case 'text':
          case 'textarea':
          case 'checkbox':
          case 'radio':
          case 'email':
          case 'password':
            $(this).one('click', function () {
              start_form_event(form_name, 'start form', getFieldName(custom_field_name, normalizeFieldName($(this).attr('name'))), $(this).parents("form"), 'start_form','information', 'body');
              form.addClass("form-sent");
            });
            break;

        }
      });

      try {
        window.Parsley.on('form:validated', function (fieldInstance) {
          let field_error_name = [];
          if ($(fieldInstance.element).hasClass("utag-form") && $(fieldInstance.element).hasClass("validate-form")) {
            $(".parsley-error, .parsley-error input").each(function () {
              if (!$(this).is(':hidden') || ($(this).is(':checkbox') && !$(this).next().hasClass('ignore-utag-validation'))) {
                let name = (($(this).data("utag-error") && $(this).data("utag-error") != '') ? $(this).data("utag-error") : $(this).attr("name"));
                if (name != undefined && name != "iberostar_api_user[accepts_privacy]") {
                  field_error_name.push(name.replace("agent_", "").replace("comercial_", "").replace("newslet", "").replace("vo_not_logged_booking", "").replace("booking_", "").replace("iberostar_api_user", "").replace("billing_", "").replace("[", "").replace("]", "").substring(0, 3));
                }
              }
            });
            if (!$(fieldInstance.element).parsley().isValid() && !fieldInstance.validationResult) {
              let field_error_name_uniq = $.unique(field_error_name);
              error_form_event($(fieldInstance.element).data("form-name"), "error", field_error_name_uniq.join("-"), $(fieldInstance.element), 'error_form', 'information', 'body');
            }
          }
          $(fieldInstance.element).removeClass("validate-form");
        });
      } catch (error) {
        console.error(error);
      }

      $("form.utag-form").not(".mice-quote-form, .ibepro-utag-register, #session-tab-registration form").find(':input:visible').each(function () {
        let form = $(this).parents("form"),
            form_name = form.data("form-name"),
            custom_field_name = $(this).data("utag-normalize-field-name");

        switch (this.type) {
          case 'select-multiple':
          case 'select-one':
            $(this).on('click', function () {
              start_form_event(form_name, 'start form', getFieldName(custom_field_name, normalizeFieldName($(this).attr('name'))), $(this).parents("form"), 'start_form','information', 'body');
              form.addClass("form-sent");
            });
            break;
          case 'text':
          case 'textarea':
          case 'checkbox':
          case 'radio':
          case 'email':
          case 'password':
            $(this).one('click', function () {
              start_form_event(form_name, 'start form', getFieldName(custom_field_name, normalizeFieldName($(this).attr('name'))), $(this).parents("form"), 'start_form','information', 'body');
              form.addClass("form-sent");
            });
            break;

        }
      });

    }

    /* Listeners */
    /*Evento para selector de destinos FB*/
    $document.on('change', '.place-to-go, #mobile-hotels-selector', function () {
      let parent = this.closest('.js-input-place-to-go')
      let isLastSearchSelected = parent ? parent.querySelector('.chosen-results .result-selected.last-search-opt') : false

      let place_value = $("#vo_booking_place_to_go").val();
      let action = isLastSearchSelected ? 'see last search' : search_action_type(place_value);

      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel',
          event_cat: 'searcher',
          event_act: action,
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });

    /* Evento para selección de campo habitaciones FB */
    $document.one('click', '.fastbooking .rooms, .custom-input-number span', function () {
      let category = 'searcher'
      let action = 'rooms'

      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel',
          event_cat: category,
          event_act: action,
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });

    /* Evento para selección de campo personas FB */
    $document.on('click', '.people, a.utag-people', function () {
      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel',
          event_cat: 'searcher',
          event_act: 'guests',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });


    $document.on('click', '#mobile-input-promo a, .promo-active a', function () {
      let resource_class = $("body").data("utag-resource-class"),
          category = (resource_class === undefined ? 'searcher' : resource_class),
          action = 'see promo code info',
          label = '';

      sendUtagEvent({
          data: {
            event_name: 'offers',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: 'information',
            event_structure: 'body',
          },
        })
    });

    $document.one('click', '.landing-typekit-header #open-legal', function () {
      let category = "offers",
          action = 'view offer conditions',
          label = '';

      sendUtagEvent({
          data: {
            event_name: 'offers',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: 'information',
            event_structure: 'header',
          },
        })
    });


    $document.on('click', '.landing-typekit-header a.utag-hotel-list-button', function () {
      let resource_class = $("body").data("utag-resource-class"),
          category = (resource_class === undefined ? 'searcher' : resource_class),
          action = 'view hotels',
          label = '';

      if(category == 'offers'){
        sendUtagEvent({
          data: {
            event_name: 'offers',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: 'inspiration',
            event_structure: 'body',
          },
        })
      }
    });

    $document.on('click', '#sort-links.drop-down-links a', function () {
      console.log('drop down');
      let resource_class = $("body").data("utag-resource-class"),
          category = (resource_class === undefined ? 'destinations' : resource_class),
          action = 'sort by',
          label = $(this).data('sort-form');
        sendUtagEvent({
          data: {
            event_name: 'sort_by',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: 'inspiration',
            event_structure: 'body',
          },
        })
    });

    $document.one('click', '.promo-code', function () {
      let category = 'searcher';
      let action = 'promotion code';

      if (typeof UtagLayerHandler === "undefined") {
        sendUtagEvent({
          data: {
            event_name: 'searcher_hotel',
            event_cat: category,
            event_act: action,
            event_lbl: '',
            event_purpose: 'booking',
            event_structure: 'body',
          },
        })
      }
    });

    /* Forms */
    $document.on('submit', 'form.new_iberostar_api_user_session, form#new_iberostar_api_user_session, form#new_iberostar_api_user, form#personal-data ,#form_validate, #form_wci, #new_vo_not_logged_booking, .utag-form .utag-form-button, .utag-form-success', function () {
      field_form_event('form', $(this).data("form-name"), "success form", '', 'success_form', '', 'body');
    });

    $document.on('click', 'form.new_iberostar_api_user_session button.btn-with-arrow, form#new_iberostar_api_user button, #form_validate button, #form_wci button, #new_vo_not_logged_booking button, .utag-form .utag-form-button', function () {
      field_form_event('form', $(this).parents("form").data("form-name"), "try send form", '', 'try_send_form', 'information', 'body');
    });

    $document.on('click', '.utag-form button, .utag-form input[type=submit], .utag-form .utag-next-btn', function () {
      $(this).parents('form:first').addClass("validate-form");
    });

    $document.on('submit', '.contact-us form', function () {
      field_form_event('form', $("form.utag-form").data("form-name"), "success form", '', 'success_form', '', 'body');
    });

    $document.on('click', '.contact-us form button', function () {
      field_form_event('form', $("form.utag-form").data("form-name"), "try send form", '', 'try_send_form', 'information', 'body');
    });

    /*Eventos call center*/
    $document.on('click', '.mobile-call-center', function () {
      let category = 'call center';
      let action = 'click contact button'
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: 'header',
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.footer-contact-container .mobile-call-center, .footer-contact-container .footer-contact-form', function () {
      let category = 'call center';
      let action = 'click contact button'
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: 'footer',
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.phone-list-number a.big-number, #phone-list-numbers ul li p a', function () {
      let category = 'call center';
      let action = 'click to call'
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: 'pop up',
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.phone-number.mobile-only', function () {
      let category = 'call center';
      let action = 'click to call'
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: 'body',
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.download', function () {
      let category = 'download',
          action = 'information room',
          label = ($(this).data("utag-hotel-download-pdf") ? $(this).data("utag-hotel-download-pdf") : ""),
          page_section = ($(this).data("utag-page-section") ? $(this).data("utag-page-section") : utag_data["page_section"]),
          page_type = ($(this).data("utag-page-type") ? $(this).data("utag-page-type") : utag_data["page_type"]),
          page_category = ($(this).data("utag-page-category") ? $(this).data("utag-page-category") : utag_data["page_category"]);
          utag_data["page_section"] = page_section;
          utag_data["page_type"] = page_type;
          utag_data["page_category"] = page_category;
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.share-list a, .share-container a.share', function() {
      let category = 'social events',
        action = 'send search by',
        label = $(this).data("network-name");
      let val_hotel_name = $(this).data("hotel-shared");
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          value: val_hotel_name,
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    // Images
    $document.on('click', '.utag-info-photos', function () {
      let category = 'hotel information',
        action = 'see multimedia info',
        label = ($(this).hasClass("swiper-fullscreen") ? 'full image' : 'photos'),
        page_view = label == 'photos';
        if($("body").hasClass("hotel-one-landing-page")){
          action = "see photos and videos";
          if($(this).parents(".header-image").length == 1){
            label = "header";
            page_view = true;
          } else
          if($(this).parents(".home-masonry-offers").length == 1){
            label = "body";
            page_view = true;
          }
          hotel_info_link(action, category, label, page_view, 'all', $(this));
        }
        else
          hotel_info_link(action, category, label, page_view, 'photos', $(this));
    });


    $document.on('click', '.utag-info-link', function () {
      let category = 'hotel information',
          action = 'see hotel info';
      hotel_info_link(action, category, '', true, 'info', $(this));
    });

    $document.on('click', '.utag-entertainment-link', function () {
      let category = 'hotel information',
          action = 'see multimedia info',
          label = $(this).data('lab-utag-hotel-info');
      hotel_info_link(action, category, label, true, 'info', $(this));
    });

    $document.on('click', '.utag-info-map', function () {
      let category = 'hotel information',
          action = 'see map';
      hotel_info_link(action, category, '', true, 'map', $(this));
    });

    /** Videos */
    $document.on('click', '.utag-play-video', function () {
      const label = $(this).data("internal-name");
      sendUtagEvent({
        data: {
          event_name: 'multimedia',
          event_cat: 'videos',
          event_act: 'play',
          event_lbl: label.toString(),
          event_purpose: 'inspiration',
          event_structure: 'body'
        },
      })
    });

    /* Evento cacheado precios */
    $document.one('click', '.availability-dates-body a.btn-bottom, a.availability', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_information',
          event_cat: 'check availability',
          event_act: 'show prices availability',
          event_lbl: $(this).data("hotel-name"),
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });

    /* Evento idioma y moneda */
    $document.on('change', 'form.locale-form select', function () {
      let category = 'change page info',
        action = 'language',
        label = $(this).val();

      sendUtagEvent({
        data: {
          event_name: 'change_language',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: 'information',
          event_structure: 'header',
        },
      })
    });


    $document.on('click', '.hotel-list-link', function () {
      sendUtagEvent({
        data: {
          event_name: 'view_by',
          event_cat: 'destinations',
          event_act: 'view by',
          event_lbl: 'list',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.hotels-info-with-filtering .hotel-map-link', function () {
      sendUtagEvent({
        data: {
          event_name: 'view_by',
          event_cat: 'destinations',
          event_act: 'view by',
          event_lbl:'map',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    /*Hotel page events*/
    $document.on('click', '.hotel-info-wrapper a.hotel-map-link', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_map',
          event_cat: 'hotel information',
          event_act: 'see map',
          event_lbl:'',
          event_purpose: 'inspiration',
          event_structure: 'popup',
        },
      })
    });

    $document.on('click', '.utag-offer-view', function () {
      sendUtagEvent({
        data: {
          event_name: 'offers_apply',
          event_cat: 'offers',
          event_act: 'apply offer',
          event_lbl: getCleanedString($(this).data('offer-title')),
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.utag-room-book-now, .room-list a.btn-with-arrow', function () {
      let category = 'book now',
        action = 'book room',
        label = $(this).data('hotel-room-name'),
        utag_hotel_info = $(this).data("utag-hotel-info");
      if (utag_hotel_info !== undefined) {
        // Initilize new visitor session id simulting GA sessions
        IB.VisitorSessionId.initNewVisitorSessionId();
      }

      sendUtagEvent({
        data: {
          event_name: 'book_room',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: 'booking',
          event_structure: 'body',
          page_variation: 'rational'
        },
      })
    });

    $document.on('click', 'input.utag-filters', function () {
      let checked = $(this).is(":checked");
      let category = 'filters destinations';

      let action = $(this).data('act-utag-filter');
      let label = $(this).data('lab-utag-filter');
      if (checked === true) {
        sendUtagEvent({
          data: {
            event_name: 'filters',
            event_cat: category,
            event_act: action,
            event_lbl: label.toString(),
            event_purpose: 'inspiration',
            event_structure: 'body'
          },
        })
      };
    });

    /*Hotel information*/
    $document.on('click', '.utag-hotel-info', function () {
      let category = $(this).data('cat-utag-hotel-info') === undefined ? 'hotel information' : $(this).data('cat-utag-hotel-info');
      let action = $(this).data('act-utag-hotel-info');
      let label = $(this).data('lab-utag-hotel-info');
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          force_cg: false,
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', '.utag-view-hotel-details', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_services',
          event_cat: 'hotel information',
          event_act: 'see all the hotel details',
          event_lbl: '',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    /* Hotel info View 360*/
    $document.on('click', '.utag-view-360', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia_360',
          event_cat: 'hotel information',
          event_act: 'see multimedia info',
          event_lbl: '360',
          event_purpose: 'inspiration',
          event_structure: 'popup',
        },
      })
    });

    /* Hotel info View 180*/
    $document.on('click', '.utag-info-views', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia_180',
          event_cat: 'my room online',
          event_act: 'see multimedia info',
          event_lbl: '180',
          event_purpose: 'inspiration',
          event_structure: 'popup',
        },
      })
    });

    $document.on('click', '.utag-room-view-more', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_rooms_details',
          event_cat: 'hotel information',
          event_act: 'see more room details',
          event_lbl: $(this).data("hotel-room-name"),
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });


    $document.on('click', '.utag-pwa-link', function () {
      let category = "download app",
        action = "progressive web app",
        label = "";
        if(sessionStorage.getItem("in-app")=="ios")
          label = "ios"
        else if (sessionStorage.getItem("in-app")=="android")
          label = "android"
        else
          label = getMobileOperatingSystem();

      sendUtagEvent({
        data: {
          event_name: 'download_app',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: 'loyalty',
          event_structure: 'body',
        },
      })
    });


    $document.on('click', "a.utag-ib-register", function() {
      let category = "lead",
        action = "click sign up",
        label = $(this).data("utag-ib-register-type");
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: '',
          event_structure: '',
        },
      })
    });

    $document.on('click', "input.utag-pre-lead", function() {
      let checked = $(this).is(":checked");
      let category = "lead",
          action = "pre lead",
          label = "";
      if (checked === true) {
        sendUtagEvent({
          data: {
            event_name: '',
            event_cat: category,
            event_act: action,
            event_lbl: label,
            event_purpose: '',
            event_structure: '',
          },
        })
      }
    });

    $(document).on('click', '.utag-menu-hotel', function(){
      sendUtagEvent({
        data: {
          event_name: 'menu_hotel',
          event_cat: 'menu-hotel detail',
          event_act: $(this).data("utag-menu-item-name"),
          event_lbl: '',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    $(document).on('click','.apply-code', function(){
      sendUtagEvent({
        data: {
          event_name: 'offers_apply',
          event_cat: 'offers',
          event_act: 'apply offer',
          event_lbl: $(this).parent(".offer-code").find(".code-number .code").html(),
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });


    $(document).on('click', '.video-filters, .utag-info-video', function(){
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia_videos',
          event_cat: 'hotel information',
          event_act: 'see multimedia info',
          event_lbl: 'videos',
          event_purpose: 'inspiration',
          event_structure: 'popup',
        },
      })
    });

    $(document).on('click', '.utag-clear-filters', function () {
      let filters_selected = []

      $(".filters-sidebar .filters input[type=checkbox]:checked").each(function(){
        filters_selected.push($(this).data("act-utag-filter").substring(0, 3))
      })

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'clear filters',
          event_lbl: '',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    // Main header
    $document.on('click', '.utag-header-logo', function () {
      sendUtagEvent({
        data: {
          event_name: 'logo',
          event_cat: 'logo',
          event_act: 'click logo iberostar',
          event_lbl: '',
          event_purpose: 'information',
          event_structure: 'header',
        },
      })
    });

    // Fastbooking
    $document.on('submit', 'form.fastbooking, form.mobile-fastbooking-form', function () {
      let isSticky = this.closest('.fastbooking-overlay-wrapper')

      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel_submit',
          event_cat: 'searcher',
          event_act: 'submit',
          event_lbl: 'search',
          event_purpose: 'booking',
          event_structure: isSticky ? 'sticky' : 'body',
        },
      })
    });

    $document.on('datepicker-change', '.utag-dates-event', function () {
      let isSticky = this.closest('.fastbooking-overlay-wrapper')

      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel',
          event_cat: 'searcher',
          event_act: 'dates',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: isSticky ? 'sticky' : 'body',
        },
      })
    });

    $document.on('click', '.utag-mobile-open-fastbooking', function () {
      sendUtagEvent({
        data: {
          event_name: 'searcher_hotel',
          event_cat: 'searcher',
          event_act: 'scroll to fastbooking',
          event_lbl: 'sticky',
          event_purpose: 'booking',
          event_structure: 'sticky',
        },
      })
    });

    // Contact modal (Search results when there is no availability)
    $document.on('click', '.utag-phone-list-see-numbers', function () {
      sendUtagEvent({
        data: {
          event_name: 'call_view_numbers',
          event_cat: 'call center',
          event_act: 'view other number',
          event_lbl: '',
          event_purpose: 'information',
          event_structure: 'popup',
        },
      })
    });

    // 404 page - See other numbers
    $document.on('click', '.utag-show-phone-numbers-404', function () {
      sendUtagEvent({
        data: {
          event_name: 'call_view_numbers',
          event_cat: 'call center',
          event_act: 'view other number',
          event_lbl: '',
          event_purpose: 'information',
          event_structure: 'body',
        },
      })
    });

    // Multimedia
    $document.on('click', '.utag-popup-show-photos', function () {
      let modalType = this.closest('[data-utag-modal-content-type]')

      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia',
          event_cat: 'hotel information',
          event_act: 'see photos and videos',
          event_lbl: modalType ? modalType.dataset.utagModalContentType : 'photos',
          event_purpose: 'inspiration',
          event_structure: 'popup',
        },
      })
    });

    $document.on('click', '.utag-masonry-show-gallery', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia',
          event_cat: 'hotel information',
          event_act: 'see photos and videos',
          event_lbl: 'body',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    // Hotel page
    $document.on('click', '.utag-see-hotel-services-modal', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_services',
          event_cat: 'hotel information',
          event_act: 'see services',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.utag-hotel-information-show-map', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_map',
          event_cat: 'hotel information',
          event_act: 'view map',
          event_purpose: 'information',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.utag-hotel-information-info-awards', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_awards',
          event_cat: 'hotel information',
          event_act: 'see hotel info',
          event_lbl: 'awards',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.repeat-reservation', function () {
      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat: 'manage my booking',
          event_act: 'repeat booking',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.utag-mro', function () {
      sendUtagEvent({
        data: {
          event_name: 'menu_main',
          event_cat: 'main-menu menu',
          event_act: 'manage my bookings',
          event_lbl: 'my room online',
          event_purpose: 'postbooking',
          event_structure: 'body',
        },
      })
    });

    $document.on('click', '.utag-wci', function () {
      sendUtagEvent({
        data: {
          event_name: 'menu_main',
          event_cat: 'main-menu menu',
          event_act: 'manage my bookings',
          event_lbl: 'check in online',
          event_purpose: 'information',
          event_structure: 'body',
        },
      })
    });

    /*End of listeners */

    IB.utag_events = {
      init:                   init,
      sendUtagEvent:          sendUtagEvent,
      cloneJson:              cloneJson,
      sendSearchEvent:        sendSearchEvent,
      get_utag_search_info: get_utag_search_info
    }


    IB.events_store = {
      storeEvent: storeEvent,
      sendStoredEvents: sendStoredEvents
    }

    IB.VisitorSessionId = {
      initNewVisitorSessionId: initNewVisitorSessionId

    }
}(jQuery));

$(function () {
  IB.utag_events.init();
});

$( window ).load(function() {
  if(document.querySelector('#is_new_login') && document.querySelector('#is_new_login').value==='true'){
    utag.link({
      event_cat: 'form',
      event_act: 'success form-login form',
      event_lbl: ''
    });
  }
});
